const useRetrieveWallets = () => {

  const getWallets = async (): Promise<any> => {
      const response = await fetch(
        'https://6lpzcch6fl.execute-api.us-east-1.amazonaws.com/dev/wallets'
      );
      return await response.json();
  };

  return { getWallets };
};

export default useRetrieveWallets;
