import './App.css';
import { Routing } from './Routing';
import AuthStore from './stores/auth.store';
import { AuthStoreContext } from './context/auth-store.context';

const authStore = new AuthStore();

function App() {

  return (
    <AuthStoreContext.Provider value={authStore}>
      <Routing />
    </AuthStoreContext.Provider>
  );
}

export default App;
