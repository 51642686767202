import { makeObservable, observable, action } from 'mobx';
import User from '../models/user';

export default class AuthStore {
    user: User = {id: '', firstName: '', lastName: '', email: ''};

    constructor() {
        makeObservable(this, {
            user: observable,
            persistUser: action,
        })
    }

    persistUser(data: any): void {
        const user = new User(data);
        this.user = user;
    }

    get getUser() {
        return this.user;
    }
}