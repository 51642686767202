import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

export default function useIsAuthenticated() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const verifyIsLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        (user) ? setIsLoggedIn(true) : setIsLoggedIn(false);
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    verifyIsLoggedIn();
  });

  return [isLoggedIn];
}
