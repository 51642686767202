import logo from '../../../assets/logo.png';
import logoWhite from '../../../assets/logo_blanc.png';
import './logo.scss'

export function Logo({...props}) {

    return (
        <>
        {(props.white) ?
            <img className="logo" src={logoWhite} alt="logo"/>
            :
            <img className="logo" src={logo} alt="logo"/>
        }
        </>
    );
}