import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Home } from './pages/home/home';
import AccountPage from './pages/account/account';
import { ForgotPassword } from './pages/forgot-password/forgot-password';
import { Profile } from './pages/profile/profile';
import { Wallets } from './pages/wallets/wallets';
import { Loyalty } from './pages/loyalty/loyalty';
import Welcome from './pages/welcome/welcome';
import useIsAuthenticated from './utils/useIsAuthenticated';
import useUserInfos from './services/auth/useUserInfos';

export function Routing() {
  const [isLoggedIn] = useIsAuthenticated();
  const { getCurrentUser } = useUserInfos();

  useEffect(() => {
    _getCurrentUser();
  },[]);

  const _getCurrentUser = async () => {
    await getCurrentUser();
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
      </Switch>
    </Router>
  );
}
