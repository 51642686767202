import React, { useState, useEffect } from 'react';
import { useNavigate } from '../../utils/useNavigate';
import { Logo } from '../../components/shared/logo/logo';
import useRetrieveWallets from '../../services/wallets/useRetrieveWallets';
import ban from '../../assets/ban.png';

import './welcome.scss';

type WelcomeProps = {};

const Welcome: React.FC<WelcomeProps> = () => {
  const [wallets, setWallets] = useState<any>([]);
  const [navigateTo] = useNavigate();
  const { getWallets } = useRetrieveWallets();

  useEffect(() => {
    _retrieveWallets();
  }, []);

  const _retrieveWallets = async () => {
    const ws = await getWallets();
    setWallets(ws);
  };

  return (
    <main className="welcome-page">
      <div className="welcome-header">
        <Logo white={true} />
        <div
          onClick={() => window.location.href = 'https://account.easy-ex.com/login'}
          className="welcome-header-button"
        >
          Get In!
        </div>
      </div>
      <div className="welcome-page-ban">
        <img className="welcome-page-ban-img" src={ban} alt="logo" />
      </div>
      <div className="welcome-page-content">
        <div className="welcome-page-content-card">
          <div>
            <Logo /> Training center
          </div>
          <p>
            Join our 100% online training center and increase your skills in
            fields of the future such as cryptocurrency, blockchain, digital
            finance and much more to come.
          </p>
          <WButton label="Join the training" onClick={() => window.location.href = 'https://training.easy-ex.com'} />
        </div>
        <div className="welcome-page-content-card">
          <div>
            <Logo /> Exchage platform
          </div>
          <p>Exchange all of these wallets with us in record time.</p>
          <div style={{ margin: '3vw' }}>
            {wallets.map((wallet: any, index: number) => (
              <img
                key={index}
                src={wallet.image}
                alt={wallet.name}
                width={70}
              />
            ))}
          </div>
        </div>
        <div className="welcome-page-content-card">
          <div>
            <Logo />
          </div>
          <p>USE DIGITAL ASSET EASILY AND SECURELY</p>
          <p style={{textAlign: 'center'}}>Evolution of means of exchange. Follow the trend !</p>
          <div>
            <img style={{maxWidth: '100%', height: 'auto'}} src="https://res.cloudinary.com/gsd/image/upload/v1654626018/easyex/auth-portal-images/money_evolution_xfbosr.jpg" alt="money evolution" />
          </div>
        </div>
      </div>
      <div className="welcome-footer">
        <p>Warining: EASY-EX is a platform for digital marketing & e-commerce, training on cryptos and on the web, exchange and support of projects and in no case an investment or investment platform of money or investment advice.</p>
        <div>
        <Logo />
        </div>
        <p>Copyright &copy; {new Date().getUTCFullYear()}</p>
      </div>
    </main>
  );
};

export default Welcome;

type WButtonProps = {
  label: any;
  onClick: any;
};
const WButton: React.FC<WButtonProps> = ({ label, onClick }) => {
  return (
    <button className="wbutton" onClick={onClick}>
      {label}
    </button>
  );
};
