import { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import {AuthStoreContext} from '../../context/auth-store.context';

const useUserInfos = () => {
  const [loading, setLoading] = useState(false);
  const authStore: any = useContext(AuthStoreContext);

  const getCurrentUser = async (): Promise<any> => {
    try {
      setLoading(true);
      const user = await Auth.currentUserInfo();
      if (!user) throw new Error('No User connected');
      authStore.persistUser(user);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserSession = async (): Promise<any> => {
    try {
        setLoading(true);
      const session = await Auth.currentSession();
      if (!session) throw new Error('No User connected');
      return session;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {loading, getCurrentUser, getUserSession};
};

export default useUserInfos;