export default class User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;

    constructor (data: any) {
        this.id = data.attributes.sub;
        this.firstName = data.attributes.given_name;
        this.lastName = data.attributes.family_name;
        this.email = data.attributes.email;

    }
}